import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as _91name_93XsInobPw8yMeta } from "/vercel/path0/pages/articles/[name].vue?macro=true";
import { default as indexHSIYyOmJPsMeta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as _91name_932NqvfUOcayMeta } from "/vercel/path0/pages/catalog/[group]/[name].vue?macro=true";
import { default as indexbiCquAxhT2Meta } from "/vercel/path0/pages/catalog/[group]/index.vue?macro=true";
import { default as indextZ3OnvocQ8Meta } from "/vercel/path0/pages/catalog/index.vue?macro=true";
import { default as index7dMBPAIfrbMeta } from "/vercel/path0/pages/conditioning/air-conditioning-maintenance/index.vue?macro=true";
import { default as indexcBdodQk6CgMeta } from "/vercel/path0/pages/conditioning/index.vue?macro=true";
import { default as indexIKTnqN2Q9mMeta } from "/vercel/path0/pages/conditioning/installing-air-conditioner/index.vue?macro=true";
import { default as indexGEho0DzgGKMeta } from "/vercel/path0/pages/conditioning/repair-air-conditioner/index.vue?macro=true";
import { default as indexH8ojxYxOKTMeta } from "/vercel/path0/pages/contacts/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexRCYZKH9YoyMeta } from "/vercel/path0/pages/ventilation/index.vue?macro=true";
import { default as indexZk5UHzgmC8Meta } from "/vercel/path0/pages/ventilation/installation-ventilation/index.vue?macro=true";
import { default as indexZmtP4h2UX1Meta } from "/vercel/path0/pages/ventilation/repair-ventilation/index.vue?macro=true";
import { default as index0JGVDO0VfOMeta } from "/vercel/path0/pages/ventilation/ventilation-maintenance/index.vue?macro=true";
export default [
  {
    name: indexutcnovDtlEMeta?.name ?? "about",
    path: indexutcnovDtlEMeta?.path ?? "/about",
    meta: indexutcnovDtlEMeta || {},
    alias: indexutcnovDtlEMeta?.alias || [],
    redirect: indexutcnovDtlEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93XsInobPw8yMeta?.name ?? "articles-name",
    path: _91name_93XsInobPw8yMeta?.path ?? "/articles/:name()",
    meta: _91name_93XsInobPw8yMeta || {},
    alias: _91name_93XsInobPw8yMeta?.alias || [],
    redirect: _91name_93XsInobPw8yMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/articles/[name].vue").then(m => m.default || m)
  },
  {
    name: indexHSIYyOmJPsMeta?.name ?? "articles",
    path: indexHSIYyOmJPsMeta?.path ?? "/articles",
    meta: indexHSIYyOmJPsMeta || {},
    alias: indexHSIYyOmJPsMeta?.alias || [],
    redirect: indexHSIYyOmJPsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_932NqvfUOcayMeta?.name ?? "catalog-group-name",
    path: _91name_932NqvfUOcayMeta?.path ?? "/catalog/:group()/:name()",
    meta: _91name_932NqvfUOcayMeta || {},
    alias: _91name_932NqvfUOcayMeta?.alias || [],
    redirect: _91name_932NqvfUOcayMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/catalog/[group]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexbiCquAxhT2Meta?.name ?? "catalog-group",
    path: indexbiCquAxhT2Meta?.path ?? "/catalog/:group()",
    meta: indexbiCquAxhT2Meta || {},
    alias: indexbiCquAxhT2Meta?.alias || [],
    redirect: indexbiCquAxhT2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/catalog/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: indextZ3OnvocQ8Meta?.name ?? "catalog",
    path: indextZ3OnvocQ8Meta?.path ?? "/catalog",
    meta: indextZ3OnvocQ8Meta || {},
    alias: indextZ3OnvocQ8Meta?.alias || [],
    redirect: indextZ3OnvocQ8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: index7dMBPAIfrbMeta?.name ?? "conditioning-air-conditioning-maintenance",
    path: index7dMBPAIfrbMeta?.path ?? "/conditioning/air-conditioning-maintenance",
    meta: index7dMBPAIfrbMeta || {},
    alias: index7dMBPAIfrbMeta?.alias || [],
    redirect: index7dMBPAIfrbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conditioning/air-conditioning-maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: indexcBdodQk6CgMeta?.name ?? "conditioning",
    path: indexcBdodQk6CgMeta?.path ?? "/conditioning",
    meta: indexcBdodQk6CgMeta || {},
    alias: indexcBdodQk6CgMeta?.alias || [],
    redirect: indexcBdodQk6CgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conditioning/index.vue").then(m => m.default || m)
  },
  {
    name: indexIKTnqN2Q9mMeta?.name ?? "conditioning-installing-air-conditioner",
    path: indexIKTnqN2Q9mMeta?.path ?? "/conditioning/installing-air-conditioner",
    meta: indexIKTnqN2Q9mMeta || {},
    alias: indexIKTnqN2Q9mMeta?.alias || [],
    redirect: indexIKTnqN2Q9mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conditioning/installing-air-conditioner/index.vue").then(m => m.default || m)
  },
  {
    name: indexGEho0DzgGKMeta?.name ?? "conditioning-repair-air-conditioner",
    path: indexGEho0DzgGKMeta?.path ?? "/conditioning/repair-air-conditioner",
    meta: indexGEho0DzgGKMeta || {},
    alias: indexGEho0DzgGKMeta?.alias || [],
    redirect: indexGEho0DzgGKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conditioning/repair-air-conditioner/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8ojxYxOKTMeta?.name ?? "contacts",
    path: indexH8ojxYxOKTMeta?.path ?? "/contacts",
    meta: indexH8ojxYxOKTMeta || {},
    alias: indexH8ojxYxOKTMeta?.alias || [],
    redirect: indexH8ojxYxOKTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCYZKH9YoyMeta?.name ?? "ventilation",
    path: indexRCYZKH9YoyMeta?.path ?? "/ventilation",
    meta: indexRCYZKH9YoyMeta || {},
    alias: indexRCYZKH9YoyMeta?.alias || [],
    redirect: indexRCYZKH9YoyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: indexZk5UHzgmC8Meta?.name ?? "ventilation-installation-ventilation",
    path: indexZk5UHzgmC8Meta?.path ?? "/ventilation/installation-ventilation",
    meta: indexZk5UHzgmC8Meta || {},
    alias: indexZk5UHzgmC8Meta?.alias || [],
    redirect: indexZk5UHzgmC8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ventilation/installation-ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: indexZmtP4h2UX1Meta?.name ?? "ventilation-repair-ventilation",
    path: indexZmtP4h2UX1Meta?.path ?? "/ventilation/repair-ventilation",
    meta: indexZmtP4h2UX1Meta || {},
    alias: indexZmtP4h2UX1Meta?.alias || [],
    redirect: indexZmtP4h2UX1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ventilation/repair-ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: index0JGVDO0VfOMeta?.name ?? "ventilation-ventilation-maintenance",
    path: index0JGVDO0VfOMeta?.path ?? "/ventilation/ventilation-maintenance",
    meta: index0JGVDO0VfOMeta || {},
    alias: index0JGVDO0VfOMeta?.alias || [],
    redirect: index0JGVDO0VfOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ventilation/ventilation-maintenance/index.vue").then(m => m.default || m)
  }
]